






















import Vue, { PropType } from 'vue';
import { ChatReaction } from '@/model/types';

export default Vue.extend({
  name: 'ChatReactionList',

  props: {
    reactions: {
      required: true,
      type: Array as PropType<ChatReaction[]>,
    },
  },

  methods: {
    clickChip(reaction: ChatReaction) {
      this.$emit('clickReaction', reaction);
    },
  },
});
