












import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import VEmojiPicker from 'v-emoji-picker';
import packEmoji from 'v-emoji-picker/data/emojis.js';

@Component({
  components: {
    VEmojiPicker,
  },
})
export default class EmojiPicker extends Vue {
  @Prop({ default: 0 }) public top!: number;
  @Prop({ default: 20 }) public right!: number;
  @Prop({ default: 200 }) public width!: number;
  @Prop({ default: 240 }) public height!: number;

  public emojisNatives = packEmoji;

  public get emojiPickerStyle() {
    const top = this.top;
    return {
      top: `${this.top}px`,
      right: `${this.right}px`,
      width: `${this.width}px`,
      height: `${this.height}px`,
    };
  }

  public pickEmoji(value: any) {
    const emoji = value.emoji as string;
    this.$emit('pickEmoji', emoji);
  }
}
