import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

export interface TodayDailyNoteState {
  selectedUserId: string | null;
}

@Module({ dynamic: true, store, name: 'today-daily-note', namespaced: true })
class TodayDailyNoteModule extends VuexModule implements TodayDailyNoteState {
  public selectedUserId: string | null = null;

  @Mutation
  public setSelectedUserId(userId: string | null) {
    this.selectedUserId = userId;
  }
}

export const todayDailyNoteModule = getModule(TodayDailyNoteModule);
