







































import Vue from 'vue';

// types
import { TaskItem } from '@/model/types';

// store
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { reportsModule } from '@/store/dataModule/report/reportsModule';

// components
import TodayTaskCard from '@/components/molecules/today/TodayTaskCard.vue';

// service
import { isMobile } from '@/service/StyleService';
import { sendEvent } from '@/service/GoogleAnalyticsService';

export default Vue.extend({
  name: 'TodayTasksContainer',

  components: {
    TodayTaskCard,
  },

  computed: {
    activeUsers() {
      return todayViewModule.activeUsers;
    },
    inactiveUsers() {
      return todayViewModule.inactiveUsers;
    },
    me() {
      return todayViewModule.me;
    },
    tags() {
      return tagModule.activatedTags;
    },
    tasks() {
      return todayViewModule.todayTasks;
    },
    isMobile() {
      return isMobile(window);
    },
    cardHeight() {
      if (this.isMobile) {
        return 'auto';
      }
      return ' 280px';
    },
  },

  methods: {
    updateTaskDone(task: TaskItem) {
      if (this.me) {
        if (task.userId !== this.me.userId) {
          return;
        }
        // この処理、reportModuleでやるべき
        const report = reportsModule.reports.find((r) => r.reportId === task.taskId);
        if (report) {
          if (task.isDone !== null && task.isDone !== undefined) {
            report.isDone = task.isDone;
          }
          reportsModule.updateTaskDone(report);

          sendEvent('task_done_tasks', {});
        }
      }
    },
    getUserTasks(userId: string) {
      return this.tasks.filter((taskItem) => taskItem.userId === userId);
    },
  },
});
