


























































import Vue, { PropType } from 'vue';
import { TodayUser } from '@/model/types';

import S3Image from '@/components/atoms/S3Image.vue';

import { getProgressColor } from '@/service/StyleService';

export default Vue.extend({
  name: 'MobileProgressCard',

  components: {
    S3Image,
  },

  props: {
    user: {
      required: true,
      type: Object as PropType<TodayUser>,
    },
  },

  methods: {
    getProgressColor(progress: number) {
      return getProgressColor(progress);
    },
  },
});
