




















import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { ToggleButton } from 'vue-js-toggle-button';

@Component({
  components: {
    ToggleButton,
  },
})
export default class CalendarShort extends Vue {
  public dayLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  public showWeekendsLocal: boolean = true;

  @Prop({ default: '' }) public start!: string;
  @Prop({ default: '' }) public end!: string;
  @Prop({ default: false }) public showToggle!: boolean;
  @Prop({ default: false }) public showDayHeader!: boolean;
  @Prop({ default: false }) public showWeekend!: boolean;
  @Prop({ default: '' }) public toggleLabel!: string;

  @Watch('showWeekends')
  public onShowWeekendsChanged(value: boolean) {
    this.showWeekendsLocal = value;
  }

  public get rows() {
    const start = dayjs(this.start);
    const end = dayjs(this.end);
    const diff = end.diff(start, 'day') + 1;
    if (start.day() === 0 && end.day() === 6) {
      return Math.floor(diff / 7);
    } else if ((start.day() - end.day()) > 1) {
      return Math.floor(diff / 7) + 2;
    } else {
      return Math.floor(diff / 7) + 1;
    }
  }

  public get calendarStart() {
    const start = dayjs(this.start);
    // return start;
    const offset = start.day();
    return dayjs(start).subtract(offset, 'day').format('YYYY-MM-DD');
  }

  public get gridStyle() {
    if (this.showWeekendsLocal) {
      return 'grid-template-columns: repeat(7, 1fr);';
    }
    return 'grid-template-columns: repeat(5, 1fr);';
  }

  public get grids() {
    return 7 * this.rows;
  }

  public getShouldShowHeader(index: number) { // index = 1 ~ 7
    if (!this.showWeekendsLocal) {
      if (index === 1 || index === 7) {
        return false;
      }
    }
    return true;
  }

  public getShouldShowCell(index: number) {
    const date = dayjs(this.calendarStart).add(index, 'day');
    const day = date.day();
    if (!this.showWeekendsLocal) {
      if (day === 0 || day === 6) {
        return false;
      }
    }
    return true;
  }

  public getCellDate(index: number) {
    const date = dayjs(this.calendarStart).add(index, 'day');
    if (index === 0 || date.date() === 1) {
      return date.format('M/D');
    }
    return date.format('D');
  }

  public getCellDateClass(index: number) {
    const start = dayjs(this.start);
    const end = dayjs(this.end);
    const date = dayjs(this.calendarStart).add(index, 'day');
    const diffStart = start.diff(date, 'day');
    const diffEnd = end.diff(date, 'day');
    if (diffStart <= 0 && diffEnd >= 0) {
      return '#012C21-light font-bold';
    }
    return '#CFD7D5';
  }

  public getCellClass(index: number) {
    const start = dayjs(this.start);
    const end = dayjs(this.end);
    const date = dayjs(this.calendarStart).add(index, 'day');
    const diffStart = start.diff(date, 'day');
    const diffEnd = end.diff(date, 'day');
    if (diffStart <= 0 && diffEnd >= 0) {
      return '';
    }
    return 'bg-ash';
  }

  public getDate(index: number)  {
    return dayjs(this.calendarStart).add(index, 'day').format('YYYY-MM-DD');
  }

  public mounted() {
    this.showWeekendsLocal = this.showWeekend;
  }
}
