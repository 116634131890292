











































































































import Vue from 'vue';

// store
import { todayViewModule, ChannelWithProgress } from '@/store/viewModule/today/todayViewModule';
import { ChannelResponse } from 'remonade-api/lib';

// components
import TodayTasksContainer from '@/container/today/TodayTasksContainer.vue';
import MobileTodayProgressContainer from '@/container/today/mobile/MobileTodayProgressContainer.vue';
import TodayJournalContainer from '@/container/today/TodayJournalContainer.vue';

// services
import LocalDataService from '@/service/LocalDataServices';
import { calcDoneRate } from '@/service/ui/StatsService';
import { getProgressColor } from '@/service/StyleService';
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';

interface DataType {
  isDrawerOpen: boolean;
  tabIndex: number;
}

export default Vue.extend({
  name: 'MobileTodayMenuContainer',

  components: {
    TodayTasksContainer,
    MobileTodayProgressContainer,
    TodayJournalContainer,
  },

  computed: {
    selectedChannelId(): string {
      return todayViewModule.selectedChannelId;
    },
    selectedChannel(): ChannelResponse | null {
      return todayViewModule.selectedChannel;
    },
    myChannels(): ChannelWithProgress[] {
      return todayViewModule.myChannelsWithProgress;
    },
    allProgress(): number {
      return todayViewModule.allTodayProgress;
    },
  },

  data: (): DataType => {
    return {
      isDrawerOpen: false,
      tabIndex: 0,
    };
  },

  methods: {
    selectChannel(channelId: string) {
      todayViewModule.selectChannel(channelId);
      this.isDrawerOpen = false;
    },
    getProgressColor(progress: number) {
      return getProgressColor(progress);
    },
    settingButtonClicked() {
      settingViewModule.setIsMobileDialogOpen(true);
    },
  },

  async mounted() {
    todayViewModule.listChannels();
    todayViewModule.getTodayData();
  },
});
