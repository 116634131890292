






















import Vue, { PropType } from 'vue';
import { TodayUser } from '@/model/types';
import DailyNote from '@/components/molecules/dailyNote/DailyNote.vue';

export default Vue.extend({
  name: 'TodayJournalCard',

  components: {
    DailyNote,
  },

  props: {
    user: {
      required: true,
      type: Object as PropType<TodayUser>,
    },
    height: {
      required: false,
      default: '280px',
      type: String,
    },
    journal: {
      required: false,
      default: '',
      type: String,
    },
  },

  methods: {
    clickJournal() {
      this.$emit('clickJournal');
    },
  },
});
