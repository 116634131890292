












import Vue from 'vue';

// types
import { TaskItem, TodayUser } from '@/model/types';

// store
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';

// components
import MobileProgressCard from '@/components/molecules/today/MobileProgressCard.vue';

interface DataType {
}

export default Vue.extend({
  name: 'MobileTodayProgressContainer',

  components: {
    MobileProgressCard,
  },

  data: (): DataType => {
    return {
    };
  },

  computed: {
    activeUsers() {
      return todayViewModule.sortedActiveUsers;
    },
    inactiveUsers() {
      return todayViewModule.inactiveUsers;
    },
    me() {
      return todayViewModule.me;
    },
  },

  methods: {
  },
});
