







































import Vue from 'vue';

import JournalContainer from '@/container/journal/JournalContainer.vue';
import JournalChannelsContainer from '@/container/journal/JournalChannelsContainer.vue';
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';

interface DataType {
  isDrawerOpen: boolean;
}

export default Vue.extend({
  name: 'MobileJournalContainer',

  components: {
    JournalContainer,
    JournalChannelsContainer,
  },

  data: () => {
    return {
      isDrawerOpen: false,
    };
  },

  methods: {
    settingButtonClicked() {
      settingViewModule.setIsMobileDialogOpen(true);
    },
  },
});
