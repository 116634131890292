











































import Vue from 'vue';

// types
import { RemonadeDailyNote, TodayUser } from '@/model/types';

// store
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';

// components
import TodayJournalCard from '@/components/molecules/today/TodayJournalCard.vue';

import { isMobile } from '@/service/StyleService';
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import { todayDailyNoteModule } from '@/store/viewModule/today/todayDailyNoteModule';
import dayjs from 'dayjs';

interface DataType {
}

interface TodayUserWithJournal {
  user: TodayUser;
  journal: string | null;
}

export default Vue.extend({
  name: 'TodayJournalContainer',

  components: {
    TodayJournalCard,
  },

  data: (): DataType => {
    return {
    };
  },

  computed: {
    activeUsers() {
      return todayViewModule.activeUsers;
    },
    teamNotes() {
      return journalViewModule.teamDailyNotes;
    },
    activeUsersWithJournal(): TodayUserWithJournal[] {
      const today = dayjs().format('YYYY-MM-DD');
      return todayViewModule.sortedActiveUsers.map((user) => {
        if (journalViewModule.teamDailyNotes) {
          const find = journalViewModule.teamDailyNotes.find((note) => note.user_id === user.nippoUserId && note.epoch === today);
          return {
            user,
            journal: find ? find.body : null,
          };
        }
        return {
          user,
          journal: null,
        };
      });
    },
    inactiveUsers() {
      return todayViewModule.inactiveUsers;
    },
    inactiveUsersWithJournal(): TodayUserWithJournal[] {
      const today = dayjs().format('YYYY-MM-DD');
      return todayViewModule.inactiveUsers.map((user) => {
        if (journalViewModule.teamDailyNotes) {
          const find = journalViewModule.teamDailyNotes.find((note) => note.user_id === user.nippoUserId && note.epoch === today);
          return {
            user,
            journal: find ? find.body : null,
          };
        }
        return {
          user,
          journal: null,
        };
      });
    },
    me() {
      return todayViewModule.me;
    },
    meWithJournal(): TodayUserWithJournal | null {
      if (todayViewModule.me) {
        const journal = journalModule.todayJournal ? journalModule.todayJournal.body : null;
        return {
          user: todayViewModule.me,
          journal,
        };
      }
      return null;
    },
    isMobile(): boolean {
      return isMobile(window);
    },
    cardHeight() {
      if (this.isMobile) {
        return 'auto';
      }
      return ' 280px';
    },
  },

  methods: {
    clickJournal(user: TodayUser) {
      todayDailyNoteModule.setSelectedUserId(user.nippoUserId);
    },
  },
});
