

































































































import Vue from 'vue';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { userStatsViewModule } from '@/store/viewModule/stats/userStatsViewModule';
import { DailyReportResponse, TeamStatsResponse } from 'remonade-api/lib';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import dayjs from 'dayjs';
import { isMobile } from '@/service/StyleService';
import { statsModule } from '@/store/dataModule/stats/statsModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { getProgressColor } from '@/service/StyleService';

export default Vue.extend({
  name: 'UserTaskCalendarContainer',

  components: {
  },

  data() {
    return {
      showWeekends: false,
      isFetchingTasks: false,
    };
  },

  computed: {
    selectedChannelReports(): DailyReportResponse[] {
      return statsViewModule.selectedChannelReports;
    },
    userStats(): TeamStatsResponse | null {
      if (statsViewModule.selectedChannelId === 'MINE') {
        if (userModule.user) {
          return statsModule.teamStats.find((ts) => ts.userId === userModule.user.userId) || null;
        }
        return null;
      }
      if (statsViewModule.selectedUserId) {
        return statsModule.teamStats.find((ts) => ts.userId === statsViewModule.selectedUserId) || null;
      }
      return null;
    },
    startDate() {
      return statsViewModule.startDate;
    },
    endDate() {
      return statsViewModule.endDate;
    },
    tags() {
      return tagModule.activatedTags;
    },
    userId() {
      return userModule.user ? userModule.user.userId : 'TEST';
    },
    currentDate() {
      return dayjs().format('YYYY-MM-DD');
    },
    weekdays() {
      if (this.showWeekends) {
        return [0, 1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5];
    },
    minDate() {
      return statsViewModule.minDate;
    },
    isMobile() {
      return isMobile(window);
    },
    selectedDate() {
      return userStatsViewModule.selectedDate;
    },
    reports() {
      return userStatsViewModule.selectedDateReports;
    },
    taskItems() {
      return userStatsViewModule.taskItems;
    }
  },

  methods: {
    getStats(dateString: string) {
      if (this.userStats) {
        // return this.userStats;
        return this.userStats.completions.find((completion) => completion.date === dateString) || null;
      }
      return [];
    },
    getProgressColor(completion: number) {
      return getProgressColor(completion / 100);
    },
    isDateInRange(dateString: string) {
      const date = dayjs(dateString);
      if (!this.minDate) {
        return true;
      } else {
        const minDate = dayjs(this.minDate);
        if (date.diff(minDate) >= 0) {
          return true;
        }
        return false;
      }
    },
    async selectDate(date: string | null) {
      userStatsViewModule.setSelectedDate(date);

      if (date) {
        this.isFetchingTasks = true;
        await userStatsViewModule.listDateReports();
        this.isFetchingTasks = false;
      }
    },
  },
});
