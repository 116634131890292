















































































































































import Vue from 'vue';
import { isMobile } from '@/service/StyleService';
import { mobileChatViewModule } from '@/store/viewModule/chat/mobileChatViewModule';
import { ChannelResponse, ChatResponse } from 'remonade-api/lib';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { ChatReaction, TodayUser } from '@/model/types';
import S3Image from '@/components/atoms/S3Image.vue';
import ChatReactionList from '@/components/molecules/chat/ChatReactionList.vue';
import EmojiPicker from '@/components/molecules/common/EmojiPicker.vue';
import dayjs from 'dayjs';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';

interface DataType {
  isDrawerOpen: boolean;
  message: string;
  emojiChatId: string | null;
}

export default Vue.extend({
  name: 'MobileChatContainer',

  components: {
    S3Image,
    ChatReactionList,
    EmojiPicker,
  },

  data: (): DataType => {
    return {
      isDrawerOpen: false,
      message: '',
      emojiChatId: null,
    };
  },

  computed: {
    isMobile(): boolean {
      return isMobile(window);
    },
    selectedChannelId(): string {
      return mobileChatViewModule.selectedChannelId;
    },
    selectedChannel(): ChannelResponse | null {
      return mobileChatViewModule.selectedChannel;
    },
    myChannels(): ChannelResponse[] {
      return channelsModule.myChannels;
    },
    chatsWithReactions() {
      return mobileChatViewModule.chatsWithReactions;
    },
    teamUsers(): TodayUser[] {
      if (mobileChatViewModule.me) {
        return [...mobileChatViewModule.teamUsers, mobileChatViewModule.me];
      }
      return mobileChatViewModule.teamUsers;
    },
    emojiPickerStyle() {
      if (this.emojiChatId) {
        const target = this.$refs[this.emojiChatId];
        if (target) {
          if (Array.isArray(target)) {
            const div = this.$refs[this.emojiChatId][0] as HTMLDivElement;
            return {
              top: `${div.getBoundingClientRect().top}px`,
            };
          }
        }
      }
      return {};
    },
    isIOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
    },
  },

  methods: {
    selectChannel(channelId: string) {
      mobileChatViewModule.selectChannel(channelId);
      this.isDrawerOpen = false;
    },
    showEmoji(userId: string) {
      // 自分を例外処理
      const user = this.teamUsers.find((u) => u.userId === userId);
      if (user) {
        return user.showEmoji;
      }
      return false;
    },
    getIcon(userId: string) {
      const user = this.teamUsers.find((u) => u.userId === userId);
      if (user) {
        if (user.showEmoji) {
          return user.emoji;
        }
        return user.iconUrl;
      }
      return null;
    },
    getUserName(userId: string) {
      const find = this.teamUsers.find((u) => u.userId === userId);
      if (find) {
        return find.username;
      }
      return '';
    },
    getDisplayDate(chat: ChatResponse) {
      const date = dayjs(chat.createdAt).format('HH:mm');
      return date;
    },
    isMine(userId: string) {
      if (mobileChatViewModule.me) {
        return userId === mobileChatViewModule.me.userId;
      }
      return false;
    },
    formatChat(message: string) {
      const rows = message.split('\n').map((c) => `<p style="margin-bottom: 0;">${c}</p>`).join('');
      const regexpUrl = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
      const regexpMakeLink = (all, url, h, href) => {
          return '<a target=\"_blank\" href="h' + href + '">' + url + '</a>';
      };
      return rows.replace(regexpUrl, regexpMakeLink);
    },
    toggleEmojiPicker(chatId: string) {
      this.emojiChatId = chatId;
    },
    async pickEmoji(emoji: string) {
      if (this.emojiChatId) {
        commonModule.setLoader(true);
        await mobileChatViewModule.addReaction({ emoji, chatId: this.emojiChatId });
        commonModule.setLoader(false);
        this.emojiChatId = null;
      }
    },
    resetFocus() {
      this.emojiChatId = null;
    },
    async clickReaction(reaction: ChatReaction, chatId: string) {
      commonModule.setLoader(true);
      await mobileChatViewModule.clickReaction({ reaction, chatId });
      commonModule.setLoader(false);
    },
    async sendMessage() {
      if (this.message.trim().length > 0) {
        const sendingMessage = this.message;
        this.message = '';
        commonModule.setLoader(true);
        await mobileChatViewModule.sendChat(sendingMessage);
        commonModule.setLoader(false);
      }
    },
    chatTime(time: string): string {
      return dayjs(time).format('HH:mm');
    },
    settingButtonClicked() {
      settingViewModule.setIsMobileDialogOpen(true);
    },
  },

  async mounted() {
    mobileChatViewModule.listChannels();
    mobileChatViewModule.getChatData();
  },
});
