
































import Vue from 'vue';

// store
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';

// models
import { UserResponse } from 'remonade-api/lib';

export default Vue.extend({
  name: 'JournalChannelsContainer',

  components: {
  },

  computed: {
    user(): UserResponse | null {
      return journalViewModule.user;
    },
    myChannels() {
      return channelsModule.myChannels;
    },
    selectedChannel() {
      return  journalViewModule.selectedChannelId;
    },
  },

  methods: {
    selectChannel(channelId: string) {
      journalViewModule.selectChannel(channelId);

      // for mobile
      this.$emit('selectChannel');
    },
  },

  async mounted() {
    if (this.user && this.user.teamId) {
      await channelsModule.listChannels();
    }
  },
});
