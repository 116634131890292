import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import { getCompletionAndTaskHourDataWithStats } from '@/service/ui/StatsService';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { statsModule } from '@/store/dataModule/stats/statsModule';

export interface CompletionAndHourState {
}

@Module({ dynamic: true, store, name: 'completion-and-hour-chart', namespaced: true })
class CompletionAndHourModule extends VuexModule implements CompletionAndHourState {
  public get completionAndHourData() {
    // calc task hours and completion by date
    return getCompletionAndTaskHourDataWithStats(statsViewModule.selectedChannelStatsCompletions, statsViewModule.startDate, statsViewModule.endDate);
  }

  public get completionAndHourDataComparison() {
    // calc task hours and completion by date
    return getCompletionAndTaskHourDataWithStats(statsViewModule.selectedChannelStatsCompletions, statsViewModule.startDate, statsViewModule.endDate);
  }
}

export const completionAndHourModule = getModule(CompletionAndHourModule);
