





































import Vue from 'vue';

import StatsChannelsContainer from '@/container/stats/StatsChannelsContainer.vue';
import StatsContainer from '@/container/stats/StatsContainer.vue';
import { settingViewModule } from '@/store/viewModule/settings/settingViewModule';

interface DataType {
  isDrawerOpen: boolean;
}

export default Vue.extend({
  name: 'MobileStatsContainer',

  components: {
    StatsChannelsContainer,
    StatsContainer,
  },

  data: () => {
    return {
      isDrawerOpen: false,
    };
  },

  methods: {
    settingButtonClicked() {
      settingViewModule.setIsMobileDialogOpen(true);
    },
  },
});
