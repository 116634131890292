










import Vue from 'vue';
import { mobileViewModule, MobileMenus } from '@/store/viewModule/mobile/mobileViewModule';

// mobile
import MobileAppContainer from '@/container/common/MobileAppContainer.vue';
import MobileTodayContainer from '@/container/today/mobile/MobileTodayContainer.vue';
import MobileJournalContainer from '@/container/journal/mobile/MobileJournalContainer.vue';
import MobileStatsContainer from '@/container/stats/mobile/MobileStatsContainer.vue';
import MobileSettingsContainer from '@/container/settings/mobile/MobileSettingsContainer.vue';
import MobileChatContainer from '@/container/chat/mobile/MobileChatContainer.vue';

// service
import { isMobile } from '@/service/StyleService';

export default Vue.extend({
  name: 'MobileScene',

  components: {
    MobileAppContainer,
    MobileTodayContainer,
    MobileJournalContainer,
    MobileStatsContainer,
    MobileSettingsContainer,
    MobileChatContainer,
  },

  computed: {
    mobileMenus() {
      return MobileMenus;
    },
    selectedMenu() {
      return mobileViewModule.selectedMenu;
    },
  },

  mounted() {
    const menuQuery = this.$route.query.menu as string;
    if (menuQuery === MobileMenus.today) {
      mobileViewModule.selectMenu(MobileMenus.today);
    } else if (menuQuery === MobileMenus.journal) {
      mobileViewModule.selectMenu(MobileMenus.journal);
    } else if (menuQuery === MobileMenus.stats) {
      mobileViewModule.selectMenu(MobileMenus.stats);
    } else if (menuQuery === MobileMenus.chat) {
      mobileViewModule.selectMenu(MobileMenus.chat);
    }
  },
});
