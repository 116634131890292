























































































import Vue from 'vue';

import { statsViewModule, ChannelWithProgress } from '@/store/viewModule/stats/statsViewModule';

import dayjs from 'dayjs';
import { getProgressColor } from '@/service/StyleService';
import { teamModule } from '@/store/dataModule/team/teamModule';

export default Vue.extend({
  name: 'StatsChannelsContainer',

  components: {
  },

  data: () => {
    return {
      dates: [dayjs().subtract(13, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
    };
  },

  watch: {
    dates: {
      immediate: true,
      deep: true,
      handler: (value: string[]) => {
        if (value.length === 2) {
          const startDate = value[0];
          const endDate = value[1];
          if (endDate >= startDate) {
            statsViewModule.setStartDate(startDate);
            statsViewModule.setEndDate(endDate);
            statsViewModule.getStatsData();
          }
        }
      },
    },
  },

  computed: {
    myChannels(): ChannelWithProgress[] {
      return statsViewModule.myChannelsWithProgress;
    },
    selectedChannelId(): string {
      return statsViewModule.selectedChannelId;
    },
    myCompletion(): number {
      return statsViewModule.myCompletion;
    },
    allCompletion(): number {
      return statsViewModule.allCompletion;
    },
    datesString(): string {
      return this.dates.map((date) => dayjs(date).format('MM/DD')).join(' - ');
    },
    isFreePlan() {
      if (teamModule.team) {
        return teamModule.team.plan.price === 0;
      }
      return false;
    },
  },

  methods: {
    selectChannel(channelId: string) {
      statsViewModule.selectChannel(channelId);

      // for mobile
      this.$emit('selectChannel');
    },
    getProgressColor(progress: number) {
      return getProgressColor(progress);
    },
    allowedDates(date: string) {
      if (this.isFreePlan) {
        const today = dayjs();
        const target = dayjs(date);
        const diff = today.diff(target, 'day');
        if (diff >= 14) {
          return false;
        }
        return true;
      }
      return true;
    },
  },

  async mounted() {
  },
});
