import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { DailyReportResponse } from 'remonade-api/lib';
import { userModule } from '@/store/dataModule/user/userModule';
import { listReports } from '@/api/client';
import { getTaskItems } from '@/service/ui/ModelService';
import { tagModule } from '@/store/dataModule/tag/tagModule';

export interface UserStatsViewState {
  selectedDateReports: DailyReportResponse[];
  selectedDate: string | null;
}

@Module({ dynamic: true, store, name: 'user-stats-view', namespaced: true })
class UserStatsViewModule extends VuexModule implements UserStatsViewState {
  public selectedDateReports: DailyReportResponse[] = [];
  public selectedDate: string | null = null;

  public get selectedUserId() {
    if (userModule.user && statsViewModule.selectedChannelId === 'MINE') {
      return userModule.user.userId;
    }
    if (statsViewModule.selectedUserId) {
      return statsViewModule.selectedUserId;
    }
    return null;
  }

  public get taskItems() {
    return getTaskItems(this.selectedDateReports, tagModule.tags);
  }

  @Mutation
  public setSelectedDate(date: string | null) {
    this.selectedDate = date;
  }

  @Action
  public async listDateReports() {
    if (userModule.user && this.selectedUserId && this.selectedDate) {
      const listResponse = await listReports({
        teamId: userModule.user.teamId,
        userId: this.selectedUserId,
        date: this.selectedDate,
        sortKey: `${userModule.user.teamId}#${this.selectedUserId}`,
        queryType: 'date_sort',
      });

      if (listResponse.error) {
        return;
      }

      if (listResponse.reports) {
        const reports = listResponse.reports;
        this.setReports(reports);
      }
    }
  }

  @Mutation
  public setReports(reports: DailyReportResponse[]) {
    this.selectedDateReports = reports;
  }
}

export const userStatsViewModule = getModule(UserStatsViewModule);
