





import { Component, Prop, Emit, Vue, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap';
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
  Placeholder,
} from 'tiptap-extensions';

@Component({
  components: {
    EditorContent,
    EditorMenuBubble,
  },
})
export default class TeamStatsUserCard extends Vue {
  public editor: Editor | null = null;

  @Prop({ default: '' }) public value!: string;

  @Watch('value')
  public onValueChanged(value, oldValue) {
    // console.log(value);
    this.setContent();
  }

  public setContent() {
    try {
      const json = JSON.parse(this.value);
      this.editor.setContent({
        type: 'doc',
        content: [json],
      }, true);
    } catch {
      this.editor.setContent(this.value);
    }

    this.editor.focus();
  }

  public mounted() {
    this.editor = new Editor({
      editable: false,
      extensions: [
        new Blockquote(),
        new BulletList(),
        new CodeBlock(),
        new HardBreak(),
        new Heading({ levels: [1, 2, 3] }),
        new ListItem(),
        new OrderedList(),
        new TodoItem(),
        new TodoList(),
        new Link(),
        new Bold(),
        new Code(),
        new Italic(),
        new Strike(),
        new Underline(),
        new History(),
        new Table({
          resizable: true,
        }),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
      ],
      content: '',
    });

    this.setContent();
  }

  public beforeDestory() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
}
