






















































import Vue, { PropType } from 'vue';
import { TaskItem, SelectOptionItem } from '@/model/types';
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';



export default Vue.extend({
  name: 'TodayTaskCard',

  props: {
    tasks: {
      required: true,
      type: Array as PropType<TaskItem[]>,
    },
    tags: {
      required: true,
      type: Array as PropType<SelectOptionItem[]>,
    },
    isMine: {
      required: false,
      default: false,
      type: Boolean,
    },
    username: {
      required: true,
      type: String,
    },
    presence: {
      required: false,
      default: false,
      type: Boolean,
    },
    totalHours: {
      required: true,
      type: Number,
    },
    height: {
      required: false,
      default: '280px',
      type: String,
    },
  },

  methods: {
    updateTaskDone(task: TaskItem) {
      const newTask = {...task};
      newTask.isDone = !newTask.isDone;
      this.$emit('updateTaskDone', newTask);
    },
    getTag(tagId: string, key: string) {
      const find = this.tags.find((tag) => tag.id === tagId);
      if (find) {
        if (key === 'name') {
          return find.label;
        } else if (key === 'color') {
          return find.color;
        }
        return null;
      }
      return null;
    },
    fabClicked() {
      taskDialogModule.setOpen(true);
    },
  },
});
