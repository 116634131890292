
















































import Vue from 'vue';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { DailyReportResponse } from 'remonade-api/lib';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { TodayUser } from '@/model/types';

export default Vue.extend({
  name: 'UserTableContainer',

  data: () => {
    return {
      search: '',
    };
  },

  computed: {
    selectedChannelReports(): DailyReportResponse[] {
      return statsViewModule.selectedChannelReports;
    },
    startDate() {
      return statsViewModule.startDate;
    },
    endDate() {
      return statsViewModule.endDate;
    },
    tags() {
      return tagModule.activatedTags;
    },
    // users
    channelUsers() {
      return statsViewModule.selectedChannelUsers;
    },
    // table data
    headers() {
      return [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          filterable: true,
          value: 'username',
        },
        {
          text: 'Completed hours',
          align: 'start',
          sortable: true,
          value: 'completedHours',
        },
        {
          text: 'Total hours',
          align: 'start',
          sortable: true,
          value: 'totalHours',
        },
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'progressData',
        },
      ];
    },
  },

  methods: {
    getDateTasks(date: string) {
      return this.selectedChannelReports
              .filter((r) => (r.type === 'task') && (r.date === date) && (r.parentId === null))
              .sort((t1, t2) => (t1.displayOrder || 0) - (t2.displayOrder || 0));
    },
    getTagName(tagId: string) {
      const find = this.tags.find((t) => t.id === tagId);
      if (find) {
        return find.label;
      }
      return null;
    },
    selectUser(user: TodayUser) {
      statsViewModule.selectUser(user.userId);
    },
  },
});
