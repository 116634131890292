










































































import Vue from 'vue';
import dayjs from 'dayjs';

// components
import CalendarShort from '@/components/molecules/common/CalendarShort.vue';
import DailyNote from '@/components/molecules/dailyNote/DailyNote.vue';
import DailyNoteCell from '@/components/molecules/dailyNote/DailyNoteCell.vue';

// store
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import { RemonadeDailyNote } from '@/model/types';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { alertModule } from '@/store/viewModule/common/alertModule';
import { planModule } from '@/store/dataModule/plan/planModule';

import { shortenJournal } from '@/service/ui/ModelService';
import { isMobile } from '@/service/StyleService';

import { UserResponse } from 'remonade-api/lib';

export default Vue.extend({
  name: 'MyJournalContainer',

  data() {
    return {
      creatingNoteDate: null as string | null,
      editingNoteDate: null as string | null,
      creatingNote: '',
      editingNote: '',
      editingNoteId: '',
      autoSaveTimerId: null as number | null,
      isEditDialogOpen: false,
      showWeekends: false,
    };
  },

  components: {
    CalendarShort,
    DailyNote,
    DailyNoteCell,
  },

  computed: {
    user(): UserResponse | null {
      return journalViewModule.user;
    },
    currentDate(): string {
      return journalViewModule.currentDate;
    },
    currentMonth(): string {
      return journalViewModule.currentMonth;
    },
    weekdays() {
      if (this.showWeekends) {
        return [0, 1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5];
    },
    monthStart(): string {
      if (dayjs(journalViewModule.currentMonth).diff(dayjs(this.minDate), 'day') < 0 && this.minDate) {
        return this.minDate;
      } else {
        return dayjs(journalViewModule.currentMonth).format('YYYY-MM-DD');
      }
    },
    monthEnd(): string {
      return dayjs(journalViewModule.currentMonth).add(1, 'month').subtract(1, 'day').format('YYYY-MM-DD');
    },
    notes(): RemonadeDailyNote[] {
      return journalModule.notes;
    },
    minDate() {
      return journalViewModule.minDate;
    },
    leftDayButtonDisabled() {
      if (this.currentDate === this.minDate) {
        return true;
      } else {
        return false;
      }
    },
    leftMonthButtonDisabled() {
      if (dayjs(this.minDate).diff(dayjs(this.currentMonth), 'day') >= 0) {
        return true;
      } else {
        return false;
      }
    },
    todayJournal() {
      return journalModule.todayJournal;
    },
    isMobile() {
      return isMobile(window);
    },
  },

  methods: {
    clickDate(date: string) {
      // this.pickDate(date);
      if (this.findNote(date)) {
        this.creatingNoteDate = null;
        this.editingNoteDate = date;
      } else {
        this.creatingNoteDate = date;
        this.editingNoteDate = null;
      }
      this.isEditDialogOpen = true;
    },
    pickMonth(date: string) {
      journalViewModule.setCurrentMonth(dayjs(date).format('YYYY-MM'));
    },
    prevDate() {
      const day = dayjs(this.currentDate).subtract(1, 'day');
      journalViewModule.setCurrentMonth(day.format('YYYY-MM'));
    },
    nextDate() {
      const day = dayjs(this.currentDate).add(1, 'day');
      journalViewModule.setCurrentMonth(day.format('YYYY-MM'));
    },
    prevMonth() {
      journalViewModule.setCurrentMonth(dayjs(this.currentMonth).subtract(1, 'month').format('YYYY-MM'));
    },
    nextMonth() {
      journalViewModule.setCurrentMonth(dayjs(this.currentMonth).add(1, 'month').format('YYYY-MM'));
    },
    isDateInRange(dateString: string) {
      const date = dayjs(dateString);
      if (!this.minDate) {
        return true;
      } else {
        const minDate = dayjs(this.minDate);
        if (date.diff(minDate) >= 0) {
          return true;
        }
        return false;
      }
    },
    async listMyNotes() {
      if (this.user && this.user.nippoUserId && this.user.teamId) {
        journalModule.setNippoUserId(this.user.nippoUserId);
        await journalModule.listMyDailyNotes({
          teamId: this.user.teamId,
          from: this.monthStart,
          to: this.monthEnd,
        });
      }
    },
    findNote(date: string) {
      if (this.notes) {
        const find = this.notes.find((note) => note.epoch === date);
        const len = this.isMobile ? 20 : 60;
        return find ? shortenJournal(find.body, len) : null;
      }
    },
    async autoSaveNote() {
      if (this.user && this.editingNoteDate && this.editingNoteId) {
        await journalModule.updateDailyNote({ documentId: this.editingNoteId, date: this.editingNoteDate, teamId: this.user.teamId, body: this.editingNote });
      } else if (this.user && this.creatingNoteDate && this.creatingNote) {
        const newNote = await journalModule.createDailyNote({ date: this.creatingNoteDate, teamId: this.user.teamId, body: this.creatingNote });
        this.editingNoteDate = newNote.epoch;
        this.creatingNoteDate = null;
      }
    },
  },

  watch: {
    currentMonth() {
      this.listMyNotes();
    },
    creatingNoteDate() {
      this.creatingNote = '';
    },
    creatingNote() {
      if (this.creatingNoteDate) {
        if (this.autoSaveTimerId) {
          clearInterval(this.autoSaveTimerId);
        }
        // auto save
        this.autoSaveTimerId = window.setTimeout(() => {
          this.autoSaveNote();
        }, 500);
      }
    },
    editingNoteDate(date) {
      const note = this.notes.find((n) => n.epoch === date);
      if (note) {
        this.editingNote = note.body;
        this.editingNoteId = note.document_id;
      } else {
        this.editingNote = '';
        this.editingNoteId = '';
      }
    },
    editingNote() {
      if (this.editingNoteId) {
        if (this.autoSaveTimerId) {
          clearInterval(this.autoSaveTimerId);
        }
        // auto save
        this.autoSaveTimerId = window.setTimeout(() => {
          this.autoSaveNote();
        }, 500);
      }
    },
    async isEditDialogOpen(value: boolean) {
      if (!value) {
        let updated: RemonadeDailyNote = null;
        if (this.user && this.editingNoteDate && this.editingNoteId) {
          updated = await journalModule.updateDailyNote({ documentId: this.editingNoteId, date: this.editingNoteDate, teamId: this.user.teamId, body: this.editingNote });
        } else if (this.user && this.creatingNoteDate && this.creatingNote) {
          updated = await journalModule.createDailyNote({ date: this.creatingNoteDate, teamId: this.user.teamId, body: this.creatingNote });
          this.editingNoteDate = updated.epoch;
          this.creatingNoteDate = null;
        }
        await journalViewModule.sentMqttNotification(updated);
        await journalViewModule.sentPushNotification();
      }
    },
  },

  async mounted() {
    await journalViewModule.getJournalData();
    await this.listMyNotes();
  },
});
