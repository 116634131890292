import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import { getTasksByDayDataWithStats } from '@/service/ui/StatsService';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';

export interface TasksByDayState {
}

@Module({ dynamic: true, store, name: 'tasks-by-day-chart', namespaced: true })
class TasksByDayModule extends VuexModule implements TasksByDayState {
  public get tasksByDayData() {
    return getTasksByDayDataWithStats(statsViewModule.selectedChannelStatsCompletions);
  }
}

export const tasksByDayModule = getModule(TasksByDayModule);
