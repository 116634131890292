import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import { getCompletionByGroupData } from '@/service/ui/StatsService';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';

export interface CompletionByGroupState {
}

@Module({ dynamic: true, store, name: 'completion-by-day-chart', namespaced: true })
class CompletionByGroupModule extends VuexModule implements CompletionByGroupState {
  public get completionByGroup() {
    if (statsViewModule.selectedChannelId !== 'ALL') { return []; }

    return getCompletionByGroupData(statsViewModule.tasksByGroup, statsViewModule.allTasks, statsViewModule.startDate, statsViewModule.endDate);
  }
}

export const completionByGroupModule = getModule(CompletionByGroupModule);
