




import Vue from 'vue';

// components
import MyJournalContainer from '@/container/journal/MyJournalContainer.vue';
import TeamJournalContainer from '@/container/journal/TeamJournalContainer.vue';

// store
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { planModule } from '@/store/dataModule/plan/planModule';
import { commonModule } from '@/store/viewModule/common/commonModule';

export default Vue.extend({
  name: 'JournalContainer',

  components: {
    MyJournalContainer,
    TeamJournalContainer,
  },

  computed: {
    selectedChannelId(): string {
      return journalViewModule.selectedChannelId;
    },
  },

  methods: {
  },

  async mounted() {
  },
});
