import { Module, VuexModule, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store/index';

import { getCompletionByTagData } from '@/service/ui/StatsService';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';

export interface CompletionByTagState {
  hiddenTagIds: string[];
}

@Module({ dynamic: true, store, name: 'completion-by-tag-chart', namespaced: true })
class CompletionByTagModule extends VuexModule implements CompletionByTagState {
  public hiddenTagIds: string[] = [];

  @Mutation
  public setHiddenTagId(tagIds: string[]) {
    this.hiddenTagIds = tagIds;
  }

  @Mutation
  public addHiddenTagId(tagId: string) {
    this.hiddenTagIds.push(tagId);
  }

  @Mutation
  public removeHiddenTagId(tagId: string) {
    this.hiddenTagIds = this.hiddenTagIds.filter((id) => id !== tagId);
  }

  public get completionByTagData() {
    return getCompletionByTagData(statsViewModule.tasksByTag, statsViewModule.startDate, statsViewModule.endDate, this.hiddenTagIds);
  }
}

export const completionByTagModule = getModule(CompletionByTagModule);
