





































































































































import Vue from 'vue';
import dayjs from 'dayjs';
import { RemonadeDailyNote } from '@/model/types';
import S3Image from '@/components/atoms/S3Image.vue';

// components
import CalendarShort from '@/components/molecules/common/CalendarShort.vue';
import DailyNote from '@/components/molecules/dailyNote/DailyNote.vue';

// store
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { planModule } from '@/store/dataModule/plan/planModule';
import { ChannelResponse, UserResponse } from 'remonade-api/lib';

export interface MyChannel {
  channelId: string;
  channelName: string;
  channelUserIds: string[];
}

export default Vue.extend({
  name: 'TeamJournalContainer',

  data() {
    return {
      focusedDate: null as string | null,
      focusedNotes: [] as RemonadeDailyNote[],
      focusedWriters: [] as UserResponse[],
      showingNote: null as RemonadeDailyNote | null,
      dialog: false as boolean,
      showWeekends: false,
    };
  },

  components: {
    S3Image,
    CalendarShort,
    DailyNote,
  },

  computed: {
    user(): UserResponse | null {
      return journalViewModule.user;
    },
    selectedChannel(): MyChannel {
      if (journalViewModule.selectedChannelId === 'ALL') {
        const allMembers: MyChannel = {
          channelId: 'ALL',
          channelName: this.$tc('allUsers'),
          channelUserIds: userModule.teamUsers.map((user) => user.userId),
        };
        return allMembers;
      } else {
        const ch = channelsModule.myChannels.filter((c) => c.channelId === journalViewModule.selectedChannelId)[0];
        const channel: MyChannel = {
          channelId: ch.channelId,
          channelName: ch.channelName,
          channelUserIds: ch.users.map((user) => user.userId),
        };
        return channel;
      }
    },
    teamUsers(): UserResponse[] {
      return journalViewModule.teamUsers;
    },
    teamDailyNotes(): RemonadeDailyNote[] {
      return journalViewModule.teamDailyNotes;
    },
    selectedChannelUsers(): UserResponse[] {
      if (this.selectedChannel.channelId !== 'ALL') {
        const mappings = channelsModule.channels.filter((m) => m.channelId === this.selectedChannel.channelId)[0].users;
        return this.teamUsers.filter((u) => {
          if (u !== undefined) {
            const find = mappings.find((m) => m.userId === u.userId);
            if (find) {
              return true;
            }
            return false;
          }
        });
      }
      return this.teamUsers;
    },
    currentDate() {
      return dayjs().format('YYYY-MM-DD');
    },
    weekdays() {
      if (this.showWeekends) {
        return [0, 1, 2, 3, 4, 5, 6];
      }
      return [1, 2, 3, 4, 5];
    },
    currentMonth(): string {
      return journalViewModule.currentMonth;
    },
    monthStart(): string {
      return journalViewModule.monthStart;
    },
    monthEnd(): string {
      return journalViewModule.monthEnd;
    },
    minDate() {
      return journalViewModule.minDate;
    },
    leftDayButtonDisabled() {
      if (this.focusedDate === this.minDate) {
        return true;
      } else {
        return false;
      }
    },
    leftMonthButtonDisabled() {
      if (dayjs(this.minDate).diff(dayjs(this.currentMonth), 'day') >= 0) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    prevMonth() {
      journalViewModule.setCurrentMonth(dayjs(this.currentMonth).subtract(1, 'month').format('YYYY-MM'));
    },
    nextMonth() {
      journalViewModule.setCurrentMonth(dayjs(this.currentMonth).add(1, 'month').format('YYYY-MM'));
    },
    openModal(date: string) {
      this.dialog = true;
      this.focusedDate = date;
      this.setFocusedNotes();
    },
    isDateInRange(dateString: string) {
      const date = dayjs(dateString);
      if (!this.minDate) {
        return true;
      } else {
        const minDate = dayjs(this.minDate);
        if (date.diff(minDate) >= 0) {
          return true;
        }
        return false;
      }
    },
    getNoteWriters(date: string) {
      const dateNotes = this.teamDailyNotes.filter((note) => note.epoch === date);
      const writers: UserResponse[] = [];
      for (const note of dateNotes) {
        if (this.user && note.user_id === this.user.nippoUserId) {
          const isExist = writers.some((w) => {
            return w === this.user;
          });
          if (!isExist) {
            writers.unshift(this.user);
          }
        } else {
          const writer = this.selectedChannelUsers.filter((u) => u.nippoUserId === note.user_id)[0];
          if (writer) {
            const isExist = writers.some((w) => {
              return w === writer;
            });
            if (!isExist) {
              writers.push(writer);
            }
          }
        }
      }
      return writers;
    },
    async getTeamNotes() {
      await journalViewModule.getTeamNotes();
    },
    setFocusedNotes() {
      if (this.user) {
        commonModule.setLoader(true);
        this.focusedNotes = this.teamDailyNotes.filter((note) => note.epoch === this.focusedDate);
        this.focusedWriters = [];
        this.showingNote = null;
        for (const note of this.focusedNotes) {
          if (this.user && note.user_id === this.user.nippoUserId) {
            const isExist = this.focusedWriters.some((w) => {
              return w === this.user;
            });
            if (!isExist) {
              this.focusedWriters.unshift(this.user);
              this.showingNote = note;
            }
          } else {
            const writer = this.selectedChannelUsers.filter((u) => u.nippoUserId === note.user_id)[0];
            if (writer) {
              const isExist = this.focusedWriters.some((w) => {
                return w === writer;
              });
              if (!isExist) {
                this.focusedWriters.push(writer);
                if (this.showingNote === null) {
                  this.showingNote = note;
                }
              }
            }
          }
        }
        commonModule.setLoader(false);
      }
    },
    setShowingNote(writer: UserResponse) {
      this.showingNote = this.focusedNotes.filter((note) => note.user_id === writer.nippoUserId)[0];
    },
    prevDate() {
      if (this.focusedDate) {
        const date = dayjs(this.focusedDate);
        this.focusedDate = date.subtract(1, 'day').format('YYYY-MM-DD');
      }
    },
    nextDate() {
      if (this.focusedDate) {
        const date = dayjs(this.focusedDate);
        this.focusedDate = date.add(1, 'day').format('YYYY-MM-DD');
      }
    },
    pickDate(date: string) {
      this.focusedDate = dayjs(date).format('YYYY-MM-DD');
    },
    pickMonth(date: string) {
      journalViewModule.setCurrentMonth(dayjs(date).format('YYYY-MM'));
      this.setFocusedNotes();
    },
  },

  watch: {
    async currentMonth() {
      await this.getTeamNotes();
    },
    focusedDate() {
      this.setFocusedNotes();
    },
  },

  async mounted() {
    await userModule.listTeamUsers();
    await this.getTeamNotes();
  },
});
